import React from 'react'
import PortfolioTemplate from '../components/PortfolioTemplate'
import { graphql } from 'gatsby'

export default ({ pageContext, data, ...rest }) => {
  if (!data || !data.prismicPortfolioPage) {
    return ''
  }
  const { data: pageData } = data.prismicPortfolioPage

  return (
    <PortfolioTemplate
      pageContext={pageContext}
      meta_title={pageData.meta_title}
      meta_description={pageData.meta_description}
      meta_image={pageData.meta_image && pageData.meta_image.url}
      data={pageData}
      {...rest} />
  )
}

export const portfolioPageQuery = graphql`
    query PortfolioPage {
        prismicPortfolioPage {
            data {
                meta_title
                meta_description
                meta_image {
                    url
                }
                category {
                  category_name
                  subtitle
                  title
                }
            }
        }
    }
`
