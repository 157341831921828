import React, { useEffect, useRef, useState } from 'react'
import Seo from '../SEO'
import { Fade } from 'react-reveal'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { LoadableQuintContentCard, LoadableQuintSidebar } from '../LoadableComponents'
import { cloneDeep } from 'lodash'
import { navigate } from 'gatsby-link'

const PortfolioTemplate = React.memo(props => {
  const {
    meta_title,
    meta_description,
    meta_image,
    pageContext,
    data
  } = props
  if (!pageContext || !pageContext.posts) {
    return ''
  }

  const isTablet = useBreakpoint('md', false)
  const isMobile = useBreakpoint('sm', false)

  const postContainer = useRef()
  const sidebarRef = useRef()

  const [selectedCategory, setSelectedCategory] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [categoryData, setCategoryData] = useState([])
  const [filteredPosts, setFilteredPosts] = useState({})

  let onSidebarLoadedListener = null

  const handleSelectedCategory = (category) => {
    if (!postContainer.current) {
      return
    }

    if (selectedCategory !== category) {
      if (!isTablet) {
        postContainer.current.classList.add('animate')
      }
      window.setTimeout(() => {
        setSelectedCategory(category)
        if (!isTablet) {
          postContainer.current.classList.remove('animate')
        }
      }, 300)
    } else {
      setSelectedCategory(category)
    }
  }

  useEffect(() => {
    const filteredPosts2 = cloneDeep(filteredPosts)
    setCategoryData(data.category)
    filteredPosts2.All = Object.values(pageContext.posts).map(el => el.node)
    filteredPosts2.All.forEach(post => {
      const key = post.data.category
      const current = filteredPosts[key]
      if (current === null || current === undefined) {
        filteredPosts2[key] = [post]
      } else {
        filteredPosts2[key].push(post)
      }
    })
    setFilteredPosts(filteredPosts2)

    handleSelectedCategory('All')

    onSidebarLoadedListener = window.addEventListener('QuintSidebar-loaded', event => {
      selectedAllAfterTimeout()
    })

    return () => {
      if (onSidebarLoadedListener) {
        window.removeEventListener('QuintSidebar-loaded', event => {
          selectedAllAfterTimeout()
        })
      }
    }
  }, [])

  const selectedAllAfterTimeout = () => {
    if (!loaded) {
      setLoaded(true)
      window.setTimeout(() => {
        handleSelectedCategory('All')
      }, 100)
    }
  }

  const Content = () => (
    <div
      className='fade-container portfolio-container' ref={postContainer}
      style={{ flex: 1, backgroundColor: 'white' }}>
      {(filteredPosts && selectedCategory && selectedCategory in filteredPosts) &&
      filteredPosts[selectedCategory].map((post, i) => {
        return (
          <div key={i}>
            {categoryData && i === 0 && categoryData.map((item, index) => {
              return (
                <div key={index}>
                  {selectedCategory === item.category_name &&
                    <div className='quint-portfolio-container top-container'>
                      <Fade bottom delay={100} disabled={isTablet}>
                        <div
                          className='quint-heading font-main color-primary'
                          style={{ padding: isMobile ? '32px 0' : '0 0 32px 0', lineHeight: 1, textAlign: 'left', fontSize: 36, width: '60%' }}>
                          {item.title}
                        </div>
                      </Fade>
                      {item.subtitle && item.subtitle.split('\n').map((item2, index) => (
                        <Fade bottom delay={300} key={index} disabled={isTablet}>
                          <div
                            className='quint-body font-main color-primary'
                            style={{ lineHeight: '19px', marginBottom: 24, textAlign: 'left' }}>
                            {item2}
                          </div>
                        </Fade>
                      ))}
                    </div>}
                </div>
              )
            })}
            <div
              style={{ padding: 32, paddingLeft: isMobile ? 32 : 48, paddingRight: isMobile ? 32 : 48 }}>
              <Fade bottom disabled={isTablet}>
                <div
                  className='quint-portfolio-container bottom-container'
                  style={{
                    border: isTablet ? '1px solid #B8C3F2' : 'none'
                  }}>
                  <LoadableQuintContentCard
                    animated
                    underlineSelected
                    mediaObjectFit={isTablet ? 'cover' : 'contain'}
                    mediaHeight={isTablet ? '192px' : '260px'}
                    mediaWidth={isTablet ? '' : '400px'}
                    mediaMaxWidth={isTablet ? '' : '400px'}
                    mediaMaxHeight={isTablet ? '192px' : '260px'}
                    className={`quint-portfolio-${post.uid}`}
                    key={post.id}
                    id={post.uid}
                    media={post.data.image ? post.data.image.url : null}
                    title={post.data.title}
                    subtitle={post.data.subtitle}
                    buttonText={post.data.button_text}
                    primaryColor='#030F4B'
                    marginBottom={isTablet ? 0 : 80}
                    onClick={() => navigate(`/portfolio/${post.uid}`)} />
                </div>
              </Fade>
            </div>
          </div>
        )
      })}
    </div>
  )

  const handleData = () => {
    return Object.keys(filteredPosts)
  }

  return (
    <div className='content-container padding-0'>
      <Seo title={meta_title} description={meta_description} image={meta_image} />
      {(Object.keys(filteredPosts).length !== 0) &&
        <LoadableQuintSidebar
          fixedHeight
          style={isMobile
            ? { height: 'unset' }
            : { maxWidth: 1376 }}
          ref={sidebarRef}
          navBar={handleData()}
          content={<Content />}
          onSelect={handleSelectedCategory} />}
    </div>
  )
})

export default PortfolioTemplate

